import { useEffect, useContext } from "react"
import { navigate } from "gatsby"
import { GA } from "gatsby-env-variables"

// Services
import services from "services"
import { updateToken } from "services/auth"

// Utils
import { getGoogleIdFormatted, getCookies, getUrlParamAll } from "utils/browser"
import getQueryStringFromObject from "utils/getQueryStringFromObject"
import { saveOnSessionStorage } from "utils/storage"

// Contexts
import { Context } from "contexts/simulation"
import { navigateWithQueryString } from "../../utils/browser"

const closedFlowSources = ["ext-icarros", "closedFlow"]

const useContinue = () => {
  const { simulation, setSimulation } = useContext(Context)

  const getTrackingData = () => {
    const currentParams = getUrlParamAll()
    const { _fbp, _ga } = getCookies()
    const sourceUrl = window?.location?.href

    return {
      sourceUrl,
      facebookClickId: currentParams?.fbclid,
      googleClickId: currentParams?.gclid,
      googleClientId: getGoogleIdFormatted(_ga),
      gaId: GA,
      facebookBrowserId: _fbp,
      googleGaCookie: _ga,
    }
  }

  useEffect(() => {
    const initContinuation = async () => {
      const { leadUUID, lead_uuid, source, token, ...args } = getUrlParamAll()
      const UUID = leadUUID ?? lead_uuid
      setSimulation({ leadUUID: UUID })

      saveOnSessionStorage({
        name: "isClosedFlow",
        value: !!source && closedFlowSources.includes(source),
      })

      const trackingData = getTrackingData()

      const simulationRecovered = await services.recovery
        .recoverSimulation({
          analytics: trackingData,
          leadUUID: UUID,
          ...args,
          token,
          source,
        })
        .catch(() => navigateWithQueryString("/"))

      const { data } = simulationRecovered

      if (data?.auth) {
        updateToken(data.auth)
        setSimulation({
          ...simulation,
          currentFormStep: data?.lastCompletedStep,
        })

        if (data?.lastCompletedStep === "personal-data") {
          return navigate(
            `/${getQueryStringFromObject({
              leadUUID: data?.leadUUID,
              ...args,
            })}`
          )
        } else {
          return navigate(
            `/formulario/${getQueryStringFromObject({
              leadUUID: data?.leadUUID,
              ...args,
            })}`
          )
        }
      }
    }

    initContinuation()
  }, [])
}

export default useContinue
